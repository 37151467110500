import store from './store'

const deeplink = {
  /**
   * ディープリンクを取得
   * ディープリンク遷移の処理フロー
   * ・facebookかつディープリンクを満たすURL形式であれば、デバイス関係なくリダイレクトページに飛ばす
   * ・リダイレクトページで「PCであれば通常のウェブページURL」「SPであればディープリンクURL」に飛ばす
   * @param {Object} collection コレクション情報
   * @return {String} ディープリンクのURL（ディープリンク非対応ならnullを返す）
   */
  async getDeeplink (collection) {
    let deeplink = null
    // ディープリンクに対応の有無の確認
    if (this.isRequiredDeeplink(collection.url)) {
      // ディープリンク対応パターン
      const result = await store.dispatch('deeplinks/getDeeplink', { cid: collection.cid })
      if (result) {
        const schema = result.schema
        const profileId = result.profileId
        if (profileId) {
          // profileIdが設定されていればQRコードをリダイレクトURLに置き換える
          deeplink = window.location.protocol + '//' + window.location.host + '/redirect/' + schema + '/' + profileId
        }
      }
    }
    return deeplink
  },
  /**
   * ディープリンク対応が必須なURLパターンを判定
   * @param {String} url 検査するurl文字列
   * @return {Boolean} ディープリンクが必要かどうか
   */
  isRequiredDeeplink (url) {
    let isRequiredDeeplink = false
    const isFacebook = /^https:\/\/www\.facebook\.com/.test(url)
    if (isFacebook) {
      /**
       * パターン処理の詳細はこちらを参照
       * @see https://gist.github.com/discoNeko/e10949592efbb87d4dcd745a9ba362fe
       *
       * 1.個人の数字id
       *  https://www.facebook.com/profile.php?id=100012461591178
       *  https://www.facebook.com/100012461591178
       * 2.個人の文字列id
       *  https://www.facebook.com/takmurat
       * 3.組織の数字id
       *  https://www.facebook.com/profile.php?id=7040724713
       *  https://www.facebook.com/7040724713
       * 4.組織の文字列id
       *  https://www.facebook.com/yahoo
       * 5.グループの数字id
       *  https://www.facebook.com/profile.php?id=155076295120010
       *  https://www.facebook.com/groups/155076295120010
       *  https://www.facebook.com/155076295120010
       * 6.グループの文字列id
       *  https://www.facebook.com/groups/DeeplinkTest
       */
      const elements = url.split('/').filter(Boolean)
      if (elements.length === 3 && (/^profile.php\?id=[0-9]*$/.test(elements[2]) || /^[0-9]*$/.test(elements[2]))) {
        /**
         * has number id
         *
         * Facebookカテゴリから登録した場合: 1,3,5が該当
         * その他URLカテゴリから登録した場合: 1,3が該当
         */
        isRequiredDeeplink = true
      } else if (elements.length === 3 && elements[2].replace(/\./g, '').length > 4 && /^[a-zA-Z0-9.]*[a-zA-Z][a-zA-Z0-9.]*$/.test(elements[2])) {
        /**
         * has string id
         * @see https://www.facebook.com/help/105399436216001
         * rules :
         *  id can only contain alphanumeric characters (A–Z, 0–9) and periods (".").
         *  id must be at least 5 characters long without periods.
         *  id must be at least 1 alphabet characters contain.
         *
         * Facebookカテゴリから登録した場合: 2,4,6が該当
         * その他URLカテゴリから登録した場合: 2,4が該当
         */
        isRequiredDeeplink = true
      } else if (elements.length === 4 && /^groups$/.test(elements[2]) && /^[a-zA-Z0-9.]*$/.test(elements[3])) {
        /**
         * has group id
         *
         * Facebookカテゴリから登録した場合: 該当なし
         * その他URLカテゴリから登録した場合: 5,6が該当
         */
        isRequiredDeeplink = true
      }
    }
    return isRequiredDeeplink
  }
}

export { deeplink }

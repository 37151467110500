<template>
  <div class="profile" v-if="!isProcessing">
    <profile-about :uid="uid" :user="user" />
    <v-btn class="profile__edit" v-if="uid === authUID"
           @click="$router.push({ name: 'profile_edit_link' })" outlined>リンクの編集</v-btn>
    <div class="profile__contents" v-if="links && displayProfileCollections">
      <profile-link class="profile__contents__link" v-for="link in links" :key="link.lid"
                    :link="link" :collections="displayProfileCollections[link.lid]" />
    </div>
    <v-progress-circular class="profile__processing" indeterminate :width="5" :size="50" v-else />
  </div>
</template>

<script>
import { deeplink } from '@/deeplink.js'

import ProfileAbout from '@/components/profile/about'
import ProfileLink from '@/components/profile/link'

export default {
  name: 'profile',
  components: { ProfileAbout, ProfileLink },
  data () {
    return {
      // 表示するユーザーのユーザーID
      uid: null
    }
  },
  async mounted () {
    // ユーザ名に紐づくユーザーIDが存在するかの確認
    this.uid = this.authUID === this.uname ? this.uname : this.$store.getters['uidUname/uid'](this.uname)
    if (!this.uid) this.uid = await this.$store.dispatch('uidUname/getUID', this.uname)
    if (!this.uid) {
      // 該当するユーザーIDが存在しないので404へ遷移させる
      this.$router.push({ name: 'notfound' })
      return
    }

    // ユーザー情報の取得
    if (!this.user) await this.$store.dispatch('users/getUser', this.uid)
    if (!this.user) {
      // ユーザー作成がまだな場合は編集画面に飛ばす
      this.$router.push({ name: 'profile_edit_info' })
      return
    }

    // フォロー情報が未取得の場合はフォロー情報を取得する（未ログインの場合は実行しない）
    if (!this.hasGotFollows && this.authUID) this.$store.dispatch('follows/getFollows', this.authUID)

    // 表示するコレクション群情報を取得
    if (!this.displayProfileCollections) this.getDisplayCollections()

    this.$store.commit('setProcessing', false)
  },
  computed: {
    /**
     * @return {Boolean} 同期処理が必要な情報を取得中かどうか
     */
    isProcessing () {
      return this.$store.getters.isProcessing
    },
    /**
     * @return {String} パラメータで指定されているユーザー名
     */
    uname () {
      return this.$route.params.uname
    },
    /**
     * @return {String} ログイン者のユーザーID
     */
    authUID () {
      return this.$store.getters['auth/uid']
    },
    /**
     * @return {Object} ユーザー情報
     */
    user () {
      return this.$store.getters['users/user'](this.uid)
    },
    /**
     * @return {Object[]} リンク情報（表示名順にソート）
     */
    links () {
      return this.uid ? this.$store.getters['links/links'](this.uid) : []
    },
    /**
     * @param {String} cid コレクションID
     * @return {Object} コレクション情報
     */
    collection () {
      return cid => {
        // 自分のプロフィールの場合はcollection、他のユーザーの場合はprofileCollectionを参照する
        return this.authUID === this.uid ? this.$store.getters['collections/collection'](cid) : this.$store.getters['collections/profileCollection'](cid)
      }
    },
    /**
     * @return {Boolean} フォロー情報を取得済みかどうか
     */
    hasGotFollows () {
      return this.$store.getters['follows/hasGot']
    },
    /**
     * @return {Object} 該当UIDのコレクション群
     */
    displayProfileCollections () {
      return this.$store.getters['collections/displayProfileCollections'](this.uid)
    }
  },
  methods: {
    /**
     * 表示するユーザーのコレクション群を取得し、storeに格納する
     */
    async getDisplayCollections () {
      // リンク情報の取得
      await this.$store.dispatch('links/getLinks', this.uid)

      // 表示するコレクション情報を格納する空のオブジェクト
      // {リンクID: {}, ...}
      const displayProfileCollections = {}
      const linkPromises = []
      // cidに基づくコレクション情報を取得
      for (const link of this.links) {
        if (!displayProfileCollections[link.lid]) displayProfileCollections[link.lid] = []
        for (const cid of link.cids) {
          if (!this.collection(cid)) linkPromises.push(this.$store.dispatch('collections/getCollection', cid))
        }
      }
      await Promise.all(linkPromises)

      // cidに基づくコレクション情報を格納
      for (const link of this.links) {
        for (const cid of link.cids) {
          const collection = this.collection(cid)

          // ディープリンクを設定（非対応ならnull）
          collection.deeplink = await deeplink.getDeeplink(collection)

          displayProfileCollections[link.lid].push(collection)
        }
      }

      // 取得したコレクション群をuidで関連付けてstoreに格納
      this.$store.commit('collections/setDisplayCollections', { uid: this.uid, displayProfileCollections: displayProfileCollections })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/sass/color.scss';
@import '@/assets/sass/size.scss';

.profile {
  position:relative;
  width: 100vw;
  max-width: $max_width;
  margin: $header_height auto $footer_height auto;
  &__edit {
    display: block;
    margin: 20px $padding_width 0 auto;
    font-size: 1.2rem;
    border-radius: 10px;
    &.v-btn:not(.v-btn--round).v-size--default {
      min-width: auto;
      height: auto;
      padding: 5px 28px;
    }
  }
  &__contents{
    &__link {
    margin-top: 10px;
    }
  }
  &__processing {
    color: $orange_color;
    position: absolute;
    bottom: -25%;
    left: 50%;
    transform: translate(-50%, 0);
  }
}
</style>

<template>
  <table class="profile-link" v-if="collections.length > 0">
    <tr class="profile-link__item">
      <th class="profile-link__item__category">{{ link.name }}</th>
      <td class="profile-link__item__link">
        <a class="profile-link__item__link__name" v-for="collection in collections" :key="collection.cid"
           :href="url(collection.deeplink || collection.url)" target="_blank">
          <div class="profile-link__item__link__name__cover">
            <v-img class="profile-link__item__link__name__cover__icon" :src="'https://www.google.com/s2/favicons?domain=' + collection.url" />
            <p class="profile-link__item__link__name__cover__text">{{ collection.collectionName }}</p>
          </div>
        </a>
      </td>
    </tr>
  </table>
</template>

<script>

export default {
  props: {
    // リンク情報
    link: {
      type: Object,
      required: true
    },
    // リンク情報に紐づくコレクション情報
    collections: {
      type: Array,
      required: true
    }
  },
  computed: {
    /**
     * @param {String} url コレクションに登録されているURL
     * @return {String} 遷移先のURL
     */
    url () {
      return url => {
        return url
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/sass/color.scss';
@import '@/assets/sass/size.scss';

.profile-link {
  width: 100%;
  padding: 0 $padding_width;
  border-spacing: 0 0;
  &__item {
    min-height: 50px;
    &__category {
      width: 100px;
      max-width: 100px;
      padding: 10px;
      font-size: 1.4rem;
      font-weight: bold;
      word-break: break-all;
      word-wrap: break-all;
      background-color: $light_gray_color;
    }
    &__link {
      width: calc(100% - 100px);
      padding: 10px 0 10px 20px;
      &__name {
        display: block;
        margin: 20px 0 0 0;
        font-size: 1.2rem;
        word-break: break-all;
        &:first-of-type {
          margin: 0;
        }
        &__cover {
          display: flex;
          align-items: top;
          &__icon {
            display: inline-block;
            flex: initial;
            width: 16px;
            height: 16px;
            margin-right: 10px;
            vertical-align: middle;
          }
          &__text {
            flex: 1;
            margin: 0;
          }
        }
      }
    }
  }
}
</style>

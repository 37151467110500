<template>
  <div class="profile-about">
    <v-img class="profile-about__background" :src="user.background" />
    <v-btn class="profile-about__share" v-if="hasUname" @click="$router.push({ name: 'profile_code', params: { uname: uname } })">
      <v-img class="profile-about__share__icon" :src="require('@/assets/img/icon.svg')" />
    </v-btn>
    <div class="profile-about__elem">
      <v-img class="profile-about__elem__icon" :src="user.icon" />
      <v-btn class="profile-about__elem__btn" text v-if="isMe"
             @click="$router.push({ name: 'profile_edit_info' })">プロフィール編集</v-btn>
      <!-- 未ログインの場合は常に「フォローする」を表示  -->
      <v-btn class="profile-about__elem__btn--follow" text v-else-if="(!isFollow && !isFollowProcessing && hasGotFollows) || !authUID"
             @click="doFollow()">フォローする</v-btn>
      <v-btn class="profile-about__elem__btn--follow" text v-else-if="!isFollow && isFollowProcessing">
        <v-progress-circular indeterminate class="profile-about__elem__btn__circular--follow" /></v-btn>
      <v-btn class="profile-about__elem__btn--unfollow" text v-else-if="isFollow && !isFollowProcessing && hasGotFollows"
             @click="doFollow()">フォロー済み</v-btn>
      <v-btn class="profile-about__elem__btn--unfollow" text v-else>
        <v-progress-circular indeterminate class="profile-about__elem__btn__circular--unfollow" /></v-btn>
    </div>
    <p class="profile-about__name">{{ user.name }}</p>
    <p class="profile-about__position">{{ user.position }}</p>
    <p class="profile-about__profile">{{ user.profile }}</p>
  </div>
</template>

<script>
export default {
  props: {
    // ユーザーID
    uid: {
      type: String,
      required: true
    },
    // ユーザー情報
    user: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      // フォロー処理中かどうか
      isFollowProcessing: false
    }
  },
  computed: {
    /**
     * @return {String} ログイン者のユーザーID
     */
    authUID () {
      return this.$store.getters['auth/uid']
    },
    /**
     * @return {Boolean} 自分自身かどうか
     */
    isMe () {
      return this.uid === this.authUID
    },
    /**
     * @return {String} パラメータで指定されているユーザー名
     */
    uname () {
      return this.$route.params.uname
    },
    /**
     * @return {Boolean} ユーザー名を作成済みか
     */
    hasUname () {
      return this.uid !== this.uname
    },
    /**
     * @return {Object} フォロー情報
     */
    follow () {
      return this.$store.getters['follows/follow'](this.uid)
    },
    /**
     * @return {Boolean} フォロー済みかどうか
     */
    isFollow () {
      return this.$store.getters['follows/isFollow'](this.uid)
    },
    /**
     * @return {Boolean} フォロー情報を取得済みかどうか
     */
    hasGotFollows () {
      return this.$store.getters['follows/hasGot']
    }
  },
  methods: {
    /**
     * フォローまたはフォロー解除を行う
     */
    async doFollow () {
      // 未ログインの場合はログインを促す
      if (!this.authUID) {
        this.$store.commit('setTelop', { show: true, msg: 'フォローするにはログインしている必要があります', type: 'warning' })
        return
      }

      this.isFollowProcessing = true

      // フォローの解除 or 開始
      this.isFollow ?
        await this.$store.dispatch('follows/deleteFollow', this.follow.fid) :
        await this.$store.dispatch('follows/addFollow', { uid: this.authUID, follow: this.uid })

      this.isFollowProcessing = false
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/sass/color.scss';
@import '@/assets/sass/size.scss';

.profile-about {
  position: relative;
  width: 100%;
  padding: 0 $padding_width;
  &__background {
    width: 100vw;
    height: 200px;
    margin-left: -$padding_width;
    background-color: $light_gray_color;
    &.v-responsive {
      max-width: $max_width;
    }
  }
  &__share {
    position: absolute;
    top: 20px;
    right: $padding_width;
    border-radius: 50%;
    &.v-btn:not(.v-btn--round).v-size--default {
      width: 50px;
      min-width: 50px;
      height: 50px;
      padding: 0;
    }
    &.theme--light.v-btn:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined) {
      background-color: $orange_lighten_color;
    }
    &__icon {
      max-width: 30px;
      height: 30px;
    }
  }
  &__elem {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    margin-top: -50px;
    &__icon {
      flex: initial;
      width: 100px;
      height: 100px;
      background-color: $light_gray_color;
      border: 4px solid $white_color;
      border-radius: 50%;
    }
    &__btn {
      font-size: 1.4rem;
      color: $black_color;
      border: 1px solid $black_color;
      border-radius: 10px;
      &.v-btn:not(.v-btn--round).v-size--default {
        min-width: 140px;
        height: 30px;
        padding: 0 14px;
      }
      &--follow {
        @extend .profile-about__elem__btn;
        color: $orange_color;
        border-color: $orange_color;
      }
      &--unfollow {
        @extend .profile-about__elem__btn;
        color: $white_color;
        background-color: $orange_color;
        border-color: $orange_color;
      }
      &__circular {
        height: 15px !important;
        &--follow {
          @extend .profile-about__elem__btn__circular;
          color: $orange_color;
        }
        &--unfollow {
          @extend .profile-about__elem__btn__circular;
          color: $white_color;
        }
      }
    }
  }
  &__name {
    margin: 15px 0 0;
    font-size: 1.8rem;
  }
  &__position {
    margin: 5px 0 0;
    font-size: 1.2rem;
    font-weight: bold;
    color: $orange_color;
  }
  &__profile {
    margin: 20px 0 0;
    font-size: 1.2rem;
    word-wrap: break-word;
    white-space: pre-wrap;
  }
}
</style>
